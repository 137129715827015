
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :label-width="'200px'">
        <el-form-item label="公文标题" prop="personId">
          {{ masterFile.docTitle }}
        </el-form-item>
        <el-form-item label="公文类型" prop="personId">
          {{ masterFile.typeName }}
        </el-form-item>
        <el-form-item label="申请人" prop="personId">
          {{ masterFile.personName }}
        </el-form-item>
        <el-form-item label="附件" prop="personId">
          <span v-for="(url, index) in fileUrls" :key="index">
            <el-image
              style="width: 80px; height: 80px"
              :src="url + '?x-oss-process=image/resize,m_fill,w_64,h_64'"
              fit="contain"
              @click="openFile(url)"
            ></el-image>
          </span>
        </el-form-item>
        <el-form-item label="申请时间" prop="personId">
          {{ masterFile.createTime }}
        </el-form-item>
        <div v-for="(examineStep, index) in examineStepList" :key="index">
        <el-form-item v-if="examineStep.examineType != 'view'" :label="index+1 + '级审批(' + examineStep.examineTypeN + ')(' + examineStep.examineStatusN +  ')'" prop="personId">
          <div v-for="(stepPerson, index2) in examineStep.stepPersonList" :key="index2">
            {{stepPerson.personName}}({{stepPerson.companyName}})({{stepPerson.statusN}})<span v-if="stepPerson.status != 0 && stepPerson.checkTime != null">(审批时间:{{stepPerson.checkTime}})</span>
            <br/>
            <span v-if="examineStep.examineType != 'view' && stepPerson.opinion != null">审批意见:{{stepPerson.opinion}}</span>
          </div>
        </el-form-item>
        <el-form-item v-if="examineStep.examineType == 'view'" :label="examineStep.examineTypeN" prop="personId">
          <div v-for="(stepPerson, index2) in examineStep.stepPersonList" :key="index2">
            {{stepPerson.personName}}({{stepPerson.companyName}})({{stepPerson.createTime}})
            <br/>
            <span v-if="examineStep.examineType != 'view' && stepPerson.opinion != null">审批意见:{{stepPerson.opinion}}</span>
          </div>
        </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import masterFileApi from "@/api/doc/masterFile";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      showDialog: true,
      loading: false,
      submitting: false,
      masterFile: {},
      examineStepList: {},
      fileUrls: [],
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    openFile(url) {
      alert(url);
      window.open(url);
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      var formData = new FormData();
      formData.append("id", self.businessKey);

      return masterFileApi.fileDetail(formData);
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.masterFile = jsonData.data.masterFile;
          self.examineStepList = jsonData.data.examineStepList;
          self.fileUrls = self.masterFile.docFiles.split(",");
          self.title = self.masterFile.docTitle;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>